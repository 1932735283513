import {IWixAPI} from '@wix/native-components-infra/dist/src/types/types'
import {GenericAPI} from '@wix/wix-events-commons-statics/dist/api/generic'
import {getLinguisticHeaderFromUrl} from '@wix/wix-events-commons-statics/dist/linguistic-header'
import {IUser} from 'native-components-infra/dist/es/src/types/types'
import {IWidgetControllerConfig} from 'native-components-infra/dist/src/types/types'
import {UPDATE_SITE_SETTINGS} from '../../commons/actions/site-settings'
import {instanceGetter} from '../../commons/services/instance'
import {SiteSettingsSettings} from '../../commons/types/state'
import {getServerBaseUrl} from '../../commons/utils/wix-code-api'
import {UPDATE_COMPONENT, UPDATE_COMPONENT_DRAFT} from '../actions/component'
import {CREATE_EVENT} from '../actions/event'
import {LOAD_MEMBERS_FOR_EVENTS, PROMPT_LOGIN} from '../actions/members'

export class Api {
  api: GenericAPI
  registrar: any

  wixCodeApi: IWixAPI
  compId: string
  language: string
  viewMode: string
  petriOvr: string
  instanceId: string
  linguisticParams: string
  getInstance: () => string

  constructor(controller: IWidgetControllerConfig) {
    const {wixCodeApi, appParams, compId} = controller

    this.compId = compId
    this.linguisticParams = this.getMultilingualParams(controller)
    this.getInstance = instanceGetter(controller)
    this.wixCodeApi = wixCodeApi
    this.language = wixCodeApi.site.language
    this.viewMode = (wixCodeApi.window.viewMode === 'Site' ? 'Site' : 'Editor').toLowerCase() // preview loads wrong data for Editor
    this.petriOvr = wixCodeApi.location.query.petri_ovr
    this.instanceId = appParams.instanceId

    this.api = new GenericAPI(getServerBaseUrl(wixCodeApi), () => this.getHeaders(controller))

    this.registrar = {
      [UPDATE_COMPONENT.NAME]: this.updateComponent,
      [UPDATE_COMPONENT_DRAFT.NAME]: this.updateComponentDraft,
      [UPDATE_SITE_SETTINGS.NAME]: this.updateSiteSettings,
      [CREATE_EVENT.NAME]: this.createEvent,
      [PROMPT_LOGIN.NAME]: this.promptLogin,
      [LOAD_MEMBERS_FOR_EVENTS.NAME]: this.getGuestLists,
    }
  }

  get(name: string) {
    const api = this.registrar[name]

    if (api) {
      return api
    }

    throw `API METHOD IS NOT REGISTERED ${name}`
  }

  getAppData = (members: boolean) => {
    const path = `/html/widget-data?instance=${this.getInstance()}&compId=${this.compId}&locale=${
      this.language
    }&viewMode=${this.viewMode}&members=${members}`

    return this.api.get(this.appendLinguisticParams(this.appendPetriOvr(path)))
  }

  updateSiteSettings = (settings: SiteSettingsSettings) => {
    return this.api.put(`/web/site-settings`, {settings})
  }

  updateComponentDraft = (type: string, component: wix.events.editor.WebComponentConfig, members: boolean = false) => {
    return this.api.put(`/web/component/${this.compId}/draft?members=${members}`, {component})
  }

  updateComponent = (component: wix.events.editor.WebComponentConfig) => {
    return this.api.put(`/web/component/${this.compId}`, {component})
  }

  createEvent = (eventData: wix.events.EventData, ADI: boolean): Promise<{event: wix.events.Event; ADI: boolean}> => {
    return this.api.post('/adi/events', eventData).then(event => ({event, ADI}))
  }

  getGuestLists = (eventIds: string[]): Promise<GuestLists> =>
    this.api.get(`/web/members?eventIds=${JSON.stringify(eventIds)}`)

  appendLinguisticParams = (path: string) => {
    return this.linguisticParams ? `${path}${this.linguisticParams}` : path
  }

  appendPetriOvr = (path: string) => (this.petriOvr ? `${path}&petri_ovr=${this.petriOvr}` : path)

  getLinguisticHeader = (controller: IWidgetControllerConfig) => {
    const {
      wixCodeApi: {
        window: {multilingual},
      },
      appParams: {
        baseUrls: {iframeUrl},
      },
    } = controller

    // TODO Remove "&& !iframeUrl" when blank template multilingual issue is fixed
    if (multilingual.isEnabled && !iframeUrl) {
      const language = multilingual.siteLanguages.find(lang => {
        return lang.languageCode === multilingual.currentLanguage
      })

      return `${language.languageCode}|${language.locale}|${language.isPrimaryLanguage}|${this.instanceId}`
    }
    if (iframeUrl) {
      return getLinguisticHeaderFromUrl(iframeUrl)
    }
  }

  getMultilingualParams = (controller: IWidgetControllerConfig) => {
    const {
      wixCodeApi: {
        window: {multilingual},
      },
    } = controller

    if (multilingual.isEnabled) {
      const language = multilingual.siteLanguages.find(lang => {
        return lang.languageCode === multilingual.currentLanguage
      })
      return `&lang=${language.languageCode}&dateNumberFormat=${language.locale}&isPrimaryLanguage=${language.isPrimaryLanguage}`
    }
  }

  getHeaders = multilingual => {
    const linguisticHeader = this.getLinguisticHeader(multilingual)

    const headers = [
      ['Authorization', this.getInstance()],
      ['Content-Type', 'application/json'],
    ]

    if (linguisticHeader) {
      headers.push(['x-wix-linguist', linguisticHeader])
    }

    return headers
  }

  promptLogin = (lang: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        await this.wixCodeApi.user.promptLogin({
          mode: 'login',
          lang,
        })
        resolve(true)
      } catch (e) {
        if (typeof e !== 'string') {
          reject(e)
          return
        }
        resolve(false)
      }
    })
  }

  onLogin = (handler: LoginHandler) => {
    this.wixCodeApi.user.onLogin(handler)
  }
}

export type LoginHandler = (user: IUser) => void
