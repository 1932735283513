import {sameMonth} from '@wix/wix-events-commons-statics/dist/date/date'
import {isEditor} from '../../commons/selectors/environment'
import {getEditorReferenceDate, getReferenceDate} from '../selectors/calendar-layout'
import {getEvents, getGroupedEventsByDate} from '../selectors/events'
import {GetState} from '../types/state'
import {SettingsTab} from './settings-panel'

export const ADD_CALENDAR_MONTH = 'ADD_CALENDAR_MONTH'
export const SUB_CALENDAR_MONTH = 'SUB_CALENDAR_MONTH'
export const SET_CALENDAR_TODAY = 'SET_CALENDAR_TODAY'
export const SET_CALENDAR_DATE = 'SET_CALENDAR_DATE'

export const OPEN_MONTHLY_CALENDAR_POPUP = 'OPEN_MONTHLY_CALENDAR_POPUP'
export const CLOSE_MONTHLY_CALENDAR_POPUP = 'CLOSE_MONTHLY_CALENDAR_POPUP'

export const OPEN_MONTHLY_CALENDAR_EVENT = 'OPEN_MONTHLY_CALENDAR_EVENT'
export const CLOSE_MONTHLY_CALENDAR_EVENT = 'CLOSE_MONTHLY_CALENDAR_EVENT'

export const addCalendarMonth = () => (dispatch, getState: GetState) => {
  dispatch({type: ADD_CALENDAR_MONTH, payload: getReferenceDate(getState())})
}

export const subCalendarMonth = () => (dispatch, getState: GetState) => {
  dispatch({type: SUB_CALENDAR_MONTH, payload: getReferenceDate(getState())})
}

export const resetCalendar = () => (dispatch, getState: GetState) => {
  const state = getState()

  if (isEditor(state)) {
    dispatch({type: SET_CALENDAR_DATE, payload: getEditorReferenceDate(state)})
  } else {
    dispatch({type: SET_CALENDAR_TODAY})
  }
}

export const openMonthlyCalendarPopup = (date: Date) => ({type: OPEN_MONTHLY_CALENDAR_POPUP, payload: {date}})
export const closeMonthlyCalendarPopup = () => ({type: CLOSE_MONTHLY_CALENDAR_POPUP})

export const openMonthlyCalendarEvent = (date: Date, eventId: string) => ({
  type: OPEN_MONTHLY_CALENDAR_EVENT,
  payload: {
    date,
    eventId,
  },
})

export const closeMonthlyCalendarEvent = () => ({
  type: CLOSE_MONTHLY_CALENDAR_EVENT,
})

export const openAnyEventDetails = () => (dispatch: Function, getState: GetState) => {
  const state = getState()
  const referenceDate = getReferenceDate(state)

  if (referenceDate) {
    const anyMonthEvent = getEvents(state).find(
      event => event.scheduling.config.startDate && sameMonth(event.scheduling.config.startDate, referenceDate),
    )

    if (anyMonthEvent) {
      dispatch(openMonthlyCalendarEvent(new Date(anyMonthEvent.scheduling.config.startDate), anyMonthEvent.id))
    }
  }
}

export const openAnyEventList = () => (dispatch: Function, getState: GetState) => {
  const state = getState()
  const referenceDate = getReferenceDate(state)

  if (referenceDate) {
    const groupedEvents = Object.entries(getGroupedEventsByDate(state, referenceDate))
    const multiEventGroup = groupedEvents.find(([, dayEvents]) => dayEvents.length > 1)

    if (multiEventGroup) {
      dispatch(openMonthlyCalendarPopup(new Date(multiEventGroup[0])))
    } else if (groupedEvents.length) {
      dispatch(openMonthlyCalendarPopup(new Date(groupedEvents[0][0])))
    }
  }
}

export const calendarSettingsTabChanged = (tab: SettingsTab) => {
  if (tab === SettingsTab.DISPLAY) {
    return openAnyEventDetails()
  } else {
    return closeMonthlyCalendarPopup()
  }
}

export const calendarSettingsSectionChanged = (id: string) => {
  if (['eventDetailsPopup', 'buttons', 'ribbons'].includes(id)) {
    return openAnyEventDetails()
  } else if (id === 'eventListPopup') {
    return openAnyEventList()
  } else {
    return closeMonthlyCalendarPopup()
  }
}

export const calendarTextsSectionChanged = (id: string) => {
  if (['rsvp-closed', 'rsvp'].includes(id)) {
    return openAnyEventDetails()
  } else {
    return closeMonthlyCalendarPopup()
  }
}
