import Experiments, {ExperimentsBag} from '@wix/wix-experiments'
import {EXPERIMENTS_SCOPE} from '../config/constants'

import {IWidgetController, IWidgetControllerConfig, IWixAPI} from '@wix/native-components-infra/dist/src/types/types'
import {createUouBiMiddlewareWithBiParams} from '@wix/wix-events-commons-statics/dist/bi/init'
import {BiParams} from '@wix/wix-events-commons-statics/dist/bi/interfaces'
import {isRtlLanguage} from '@wix/wix-events-commons-statics/dist/locale'
import {bindActionCreators, Store} from 'redux'
import {setBaseEnvironment} from '../../commons/actions/environment'
import {getSiteSettings} from '../../commons/actions/site-settings'
import {getLanguage} from '../../commons/selectors/environment'
import {isSSR} from '../../commons/utils/wix-code-api'
import {getComponentData, updateComponent} from '../actions/component'
import {closeAllEvents, getEvents, setTab, shareEvent, toggleEventDetails} from '../actions/events'
import {internalNavigate, navigateToDetailsPage} from '../actions/navigation'
import {cancelRsvp} from '../actions/rsvp'
import {updateSettings} from '../actions/sdk'
import {downloadTicketsAction} from '../actions/tickets'
import {resetToLiveView} from '../actions/view'
import * as eventsUouEvents from '../bi/uou-bi-events-map'
import reducers from '../reducers'
import {createMembersPageFedopsLogger} from '../services/fedops'
import {Actions, MemberPageState, Tabs} from '../types/state'
import {Api} from '../utils/api'
import {getMembersAPI} from '../utils/members-api'
import {createReduxStore} from '../utils/store'

export async function createMembersPageController(controller: IWidgetControllerConfig): Promise<IWidgetController> {
  const {appParams, setProps} = controller
  const experiments = await getExperimentsByScope(EXPERIMENTS_SCOPE)

  return {
    async pageReady() {
      try {
        const fedopsLogger = createMembersPageFedopsLogger(controller)
        const store = await createStore(controller, experiments)
        const actions = exportedActions(store, fedopsLogger.onAppLoaded)
        const state = store.getState()

        setProps({
          state,
          actions,
          cssBaseUrl: appParams.baseUrls.staticsBaseUrl,
          isRTL: isRtlLanguage(getLanguage(state)),
        })

        await Promise.all([actions.getEvents(Tabs.UPCOMING), actions.getEvents(Tabs.PAST), actions.getComponentData()])

        if (isSSR(controller.wixCodeApi)) {
          fedopsLogger.onSSRPageReady()
        }
      } catch (e) {
        console.error(e)
        throw e
      }
    },
  }
}

const createBiMiddleware = (biParams: BiParams) => [createUouBiMiddlewareWithBiParams(biParams, eventsUouEvents)]

const createStore = async (controller: IWidgetControllerConfig, experiments: ExperimentsBag) => {
  const serverApi = new Api(controller)
  const viewedSiteMemberId = await getSiteMemberId(controller)
  const {multilingual} = controller.wixCodeApi.window

  const initialData: Partial<MemberPageState> = {
    experiments,
    multilingual: {
      currentLanguage: multilingual.currentLanguage,
      isEnabled: multilingual.isEnabled,
    },
    user: {
      currentUserId: controller.wixCodeApi.user.currentUser.id,
      viewedSiteMemberId,
    },
  }

  const middleware = createBiMiddleware({
    wixCodeApi: controller.wixCodeApi,
    platformAPIs: controller.platformAPIs,
    appParams: controller.appParams,
    compId: controller.compId,
    user: initialData.user,
  })

  const store = createReduxStore({
    initialData,
    extraArguments: {
      wixCodeApi: controller.wixCodeApi,
      serverApi,
      compId: controller.compId,
      baseUrl: controller.appParams.baseUrls.baseUrl,
      pageUrl: await getPageUrl(controller),
    },
    reducers,
    middleware,
  })

  await store.dispatch(<any>getSiteSettings())
  await store.dispatch(<any>setBaseEnvironment())

  subscribeToStateChanges(controller, store)

  return store
}

const subscribeToStateChanges = (controller: IWidgetControllerConfig, store: Store) => {
  const onStateChange = () => {
    const state = store.getState()
    controller.setProps({state})
  }

  store.subscribe(onStateChange)
}

const getSiteMemberId = async (controller: IWidgetControllerConfig) =>
  controller.appParams.baseUrls.siteMemberId || (await getViewedUserId(controller.wixCodeApi))

const getViewedUserId = async (wixCodeApi: IWixAPI) => {
  if (wixCodeApi.window.viewMode === 'Editor') {
    return ''
  }
  try {
    const membersApi = await getMembersAPI(wixCodeApi)
    return await membersApi.getViewedUser()
  } catch (e) {
    const currentUser = wixCodeApi.user.currentUser
    if (currentUser.loggedIn) {
      return currentUser.id
    }
    throw e
  }
}

const getPageUrl = async ({wixCodeApi}: IWidgetControllerConfig): Promise<string> => {
  const pageUrl = await wixCodeApi.site.getSectionUrl({sectionId: 'events'})
  return (pageUrl && pageUrl.url) || null
}

const getExperimentsByScope = async (scope: string) => {
  const experiments = new Experiments({
    scope,
  })
  await experiments.ready()
  return experiments.all()
}

const exportedActions = (store: Store, appLoaded): Actions => ({
  ...bindActionCreators(
    {
      getComponentData,
      getEvents,
      toggleEventDetails,
      cancelRsvp,
      shareEvent,
      getSiteSettings,
      navigateToDetailsPage,
      internalNavigate,
      updateSettings,
      updateComponent,
      setTab,
      resetToLiveView,
      downloadTicketsAction,
      closeAllEvents,
    },
    store.dispatch,
  ),
  appLoaded,
})
